import { Content, InfoCard, LinkButton, Page } from '@backstage/core-components';
import { HomePageStarredEntities } from '@backstage/plugin-home';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { NewReleaseNoteBanner, ReleaseNotesCard } from '@internal/plugin-release-notes';
import { Card, CardContent, CardHeader, Divider, Grid, Link, Typography } from '@material-ui/core';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';
import React from 'react';


export const HomePage = () => {
  // const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container>
            <Grid item md={12}>
              <NewAnnouncementBanner/>
              <NewReleaseNoteBanner/>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card>
                  <CardHeader
                    title={<Typography variant="h5">Welcome to <Link href="https://devhub.cloud.cae.com/docs/default/component/developer-corner/Info/portal_info/">DevHub</Link></Typography>}
                  />
                  <CardContent>
                    <Typography>
                    We are proud to introduce our platform for developers, complete with a rich array of resources to guide you through every phase of your development journey.
                    While here, you'll find tools, tutorials, and documentation designed to support and elevate your skills.
                    Experience the next generation of software development at CAE.
                    This platform is crafted to streamline your workflow, foster innovation, and help you reach new heights in your projects.
                    Join us on this exciting journey towards new horizons in technology!
                    </Typography>
                  </CardContent>
                  <Divider/>
                  <CardContent>
                    <LinkButton to="docs/default/component/developer-corner/Info/portal_info" size="small" color="primary" variant="outlined">Get Started</LinkButton>
                  </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <AnnouncementsCard title="Announcements" max={3}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <ReleaseNotesCard title="Release Notes" max={3} />
            </Grid>
            <Grid item xs={12} md={6}>
              <HomePageStarredEntities title="Favorites" />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard title="Platform Engineering Team Assistance">
                <Typography>
                    Help requests directed to the{' '}
                    <Link
                      href="https://devhub.cloud.cae.com/catalog/default/group/it_azure_platformteam_group"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Platform Engineering Team
                    </Link>{' '}
                    must be initiated via an{' '}
                    <Link href="https://cae-dwp.onbmc.com/dwp/app/#/srm/profile/SRGHRAG0BWQDSARU4OOWRU4OOWJXFX/srm" target="_blank" rel="noopener noreferrer">
                      {' '}
                      ITSM{' '}
                    </Link>{' '}
                    request.
                 </Typography>
              </InfoCard>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
